.webshop .p-galleria .p-galleria-thumbnail-container {
    /* border-style: solid; */
    background-color: #8f9295;
    border: none;
    border-bottom-left-radius: 0.9rem;
    border-bottom-right-radius: 0.9rem;
}

.webshop .gallery-container {
    border: 2px solid;
    border-radius: 1rem;
    /* border-bottom-left-radius: 1rem; */
    /* border-bottom-right-radius: 1rem; */
}

.webshop .gallery-image {
    display: block;
    width: 100%;
    border-top-left-radius: 0.9rem;
    border-top-right-radius: 0.9rem;
}

.webshop .gallery-thumbnail {
    display: block;
    width: 70%;
    border-radius: 0.5rem;
    background-color: white;
}

.webshop .p-galleria-item-next {
    width: 40px;
}

.webshop .p-galleria-item-prev {
    width: 40px;
}

.webshop
    .p-galleria-item-nav-onhover
    .p-galleria-item-wrapper:hover
    .p-galleria-item-nav {
    /* opacity: 0; */
    background-color: #8f9295;
}

.webshop .p-tabview-panels {
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
}

.webshop .ql-editor {
    padding-left: 0px;
    padding-right: 0px;
}

.webshop .ql-editor h1 {
    margin-bottom: 0.5rem;
}

.webshop .ql-editor h2 {
    margin-bottom: 0.5rem;
}

.webshop .ql-editor h3 {
    margin-bottom: 0.5rem;
}

.webshop .ql-editor h4 {
    margin-bottom: 0.5rem;
}

.webshop .ql-editor h5 {
    margin-bottom: 0.5rem;
}

.webshop .ql-editor p {
    margin-bottom: 0.5rem;
}

.webshop .ql-editor ul {
    margin-bottom: 0.5rem;
}

.webshop .ql-editor ol > li {
    margin-bottom: 0.5rem;
}

.webshop .ql-editor li {
    margin-bottom: 0rem;
}

.webshop .p-tabview-nav {
    max-height: 0px;
    border: 0px;
}

.webshop .tab .p-tabview-nav {
    max-height: max-content;
    border: 1px solid #dee2e6;
    border-width: 0 0 2px 0;
}

.slideover-cart {
    height: max-content;
    min-height: max-content;
    max-height: max-content;
    background-color: red;
    top: 60px;
}

.slideover-cart .cart-product-list {
    display: block;
    background-color: white;
    height: max-content;
    min-height: max-content;
    max-height: 70vh;
}

.webshop .toast button {
    min-width: 10rem;
    margin-right: 0.5rem;
}

/* .webshop .product-details .p-dialog .p-component {
    width: 90% !important;
} */
#product-details {
    width: 750px !important;
}
@media screen and (max-width: 750px) {
    #product-details {
        width: 95% !important;
    }
}

@media screen and (max-width: 960px) {
    .webshop .toast button {
        width: 100%;
        margin-bottom: 0.5rem;
    }
}
