span {
    font-weight: 600;
}

p {
    margin-top: 5px;
    color: DimGray;
    line-height: 1.5;
}

input[readonly] {
    background-color: #f8eec8;
}

input[disabled] {
    color: blue;
    background-color: silver;
}

.error-input {
    background-color: #ee857d;
}

.footer-link .p-button.footer-button-w-fix {
    width: 180px;
}

.footer-link .p-button.instagram {
    background: linear-gradient(
        to left,
        var(--purple-600) 50%,
        var(--purple-700) 50%
    );
    background-size: 200% 100%;
    background-position: right bottom;
    transition: background-position 0.5s ease-out;
    color: #fff;
    border-color: var(--purple-700);
}
.footer-link .p-button.instagram:hover {
    background-position: left bottom;
}
.footer-link .p-button.instagram:focus {
    box-shadow: 0 0 0 1px var(--purple-400);
}

.footer-link .p-button.facebook {
    background: linear-gradient(
        to left,
        var(--indigo-600) 50%,
        var(--indigo-700) 50%
    );
    background-size: 200% 100%;
    background-position: right bottom;
    transition: background-position 0.5s ease-out;
    color: #fff;
    border-color: var(--indigo-700);
}
.footer-link .p-button.facebook:hover {
    background-position: left bottom;
}
.footer-link .p-button.facebook:focus {
    box-shadow: 0 0 0 1px var(--indigo-400);
}

.footer-link .p-button.youtube {
    background: linear-gradient(
        to left,
        var(--pink-600) 50%,
        var(--pink-700) 50%
    );
    background-size: 200% 100%;
    background-position: right bottom;
    transition: background-position 0.5s ease-out;
    color: #fff;
    border-color: var(--pink-700);
}
.footer-link .p-button.youtube:hover {
    background-position: left bottom;
}
.footer-link .p-button.youtube:focus {
    box-shadow: 0 0 0 1px var(--pink-400);
}

.footer-link a:link {
    text-decoration: none;
}
.footer-link a:visited {
    text-decoration: none;
}
.footer-link a:hover {
    text-decoration: none;
}
.footer-link a:active {
    text-decoration: none;
}

.tabview-header-icon span {
    margin: 0 0.5rem;
}

.quill {
    border-radius: 6px !important;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.ql-toolbar {
    border-radius: 6px !important;
}

.ql-container {
    border: 0px;
    border-radius: 6px !important;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    width: 100%;
}

.editor .ql-editor {
    border: 1px solid #ced4da;
    border-radius: 6px !important;
    overflow: auto;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    position: absolute;
    width: 100%;
}

.editor .ql-editor:hover {
    border-color: #6465e9 !important;
    border: 1px solid #6465e9 !important;
}

.editor .ql-editor:focus {
    border-color: #6465e9 !important;
    border: 1px solid #6465e9 !important;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(101, 101, 225, 1);
    -moz-box-shadow: 0px 0px 5px 0px rgba(101, 101, 225, 1);
    box-shadow: 0px 0px 5px 0px rgba(101, 101, 225, 1);
}

.p-splitter.p-invalid {
    border-color: #e24c4c;
}

.p-menubar-root-list {
    min-width: 220px;
}

.p-treetable {
    display: grid;
}
.p-treetable-wrapper {
    margin: 0;
    overflow-x: scroll;
    table-layout: auto;
}

.p-treetable .p-treetable-thead th {
    vertical-align: middle;
    text-align: center !important;
}

.p-treetable .p-treetable-tbody tr {
    vertical-align: middle;
}

.p-column-title {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.p-component-overlay {
    position: fixed;
    top: 0px;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.p-menubar-root-list {
    left: -180px !important;
}
