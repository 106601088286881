/* ul {
    list-style: square outside none !important;
} */

/* li {
    line-height: 3.5;
} */

/* .social-network {
    vertical-align: middle;
    display: inline-block;
    text-align: center;
} */

.social-network-share-button {
    cursor: pointer;
}

.social-network-share-button:hover {
    opacity: 0.75;
}
