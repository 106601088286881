.checkout .p-steps ul {
    display: block !important;
}

.checkout td {
    vertical-align: middle;
}

.checkout th {
    vertical-align: middle;
}

.checkout .p-column-header-content {
    display: block;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.checkout .p-inputnumber-input {
    width: 70px !important;
}
