.p-avatar img {
    width: auto;
    min-width: 0;
}
.quotes .p-carousel-items-content {
    display: block !important;
    justify-content: center;
    align-items: center;
    margin-bottom: 2px;
}

.quotes .p-carousel-item {
    height: 0px;
}

.quotes .p-carousel-item-active {
    height: min-content;
    min-height: min-content !important;
    max-width: max-content;
    justify-content: center;
    align-items: center;
}

.quotesIndicators {
    display: none;
}

.quotesContainer {
    max-height: max-content;
    max-width: max-content;
}

.quotesContent {
    margin-top: 0;
    margin-bottom: 2;
    padding-top: 0;
    padding-bottom: 0;
    max-height: max-content;
    max-width: max-content;
    position: relative;
}

@media only screen and (max-width: 992px) {
    .quotes {
        margin-left: auto;
        margin-right: auto;
        width: 80vw;
    }
}

@media only screen and (min-width: 992px) {
    .quotes {
        margin-left: auto;
        margin-right: auto;
        width: 65vw;
    }
}
