.responsive {
    display: block;
    padding-top: 0%;
    padding-bottom: 0%;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 60%;
    height: auto;
}
