ol {
    list-style-type: upper-roman;
    counter-reset: item;
    margin: 10px;
    padding: 0;
}

ol > li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.4em;
}

ol > li:before {
    color: black;
    font-weight: 600;
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.5em;
}

li ol > li {
    margin: 10px 0 0 0;
}

li ol > li:before {
    content: counters(item, ".") " ";
}

ul {
    margin-top: 5px;
    color: DimGray;
    line-height: 1.5;
}

li {
    margin-top: 5px;
    color: DimGray;
    line-height: 1.5;
}

a {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

table {
    margin: 0;
    border-collapse: collapse;
    overflow-x: scroll;
    display: block;
    table-layout: auto;
    max-width: fit-content;
    width: 60vw;
}

th,
td {
    background: white;
    border: solid DimGray 1px;
    padding: 0.5rem;
    vertical-align: top;
}

th {
    color: white;
    background: #bfa875;
}
