.login-error {
    text-align: center !important;
    margin-top: 10px;
}

#dateOfBirth {
    /* color: red; */
    font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
}

.MuiFormLabel-root {
    border-radius: 6px !important;
}

.MuiOutlinedInput-root {
    border-radius: 6px !important;
}

.MuiOutlinedInput-root:hover {
    border-color: #6465e9 !important;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #6465e9 !important;
}

.MuiFormLabel-root.Mui-focused {
    border-color: #6465e9 !important;
    border: 1px solid #6465e9 !important;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(101, 101, 225, 1);
    -moz-box-shadow: 0px 0px 5px 0px rgba(101, 101, 225, 1);
    box-shadow: 0px 0px 5px 0px rgba(101, 101, 225, 1);
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #6465e9 !important;
    border: 1px solid #6465e9 !important;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(101, 101, 225, 1);
    -moz-box-shadow: 0px 0px 5px 0px rgba(101, 101, 225, 1);
    box-shadow: 0px 0px 5px 0px rgba(101, 101, 225, 1);
}

.password-panel-right {
    margin-left: 45px;
    padding-left: 10px;
    padding-right: 10px;
    /* margin-top: -395px; */
}
